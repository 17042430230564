<template>
  <v-container
    class="pa-0"
    fluid
  >
    <FilterHeader
      @filter="filterAction"
      @clear="clearAction"
    />

    <v-row dense>
      <v-col cols="8">
        <v-text-field
          v-model="filter.code"
          outlined
          label="Nº do orçamento"
          rounded
          dense
          hide-details
        />
      </v-col>
      <v-col cols="4">
        <v-select
          v-model="filter.status"
          outlined
          :items="status"
          item-text="name"
          item-value="id"
          label="Status"
          rounded
          dense
          hide-details
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="8">
        <v-autocomplete
          v-model="filter.seller_id"
          outlined
          label="Representante"
          :items="seller.items"
          :item-text="'name'"
          :item-value="'id'"
          rounded
          dense
          hide-details
          @focus.once="getSellers"
        >
          <template v-slot:no-data>
            <v-progress-linear
              v-if="seller.loading"
              indeterminate
            />
            <h6
              v-else
              class="text-h6 grey--text text-center"
            >
              Nenhum vendedor encontrado
            </h6>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col cols="4">
        <v-autocomplete
          v-model="filter.motive_reprovation_id"
          outlined
          label="Motivo de Reprovação"
          :items="cancel_reasons.items"
          :item-value="'name'"
          :item-text="'name'"
          rounded
          dense
          hide-details
          @focus.once="getCancelReasons"
        >
          <template v-slot:no-data>
            <v-progress-linear
              v-if="company.loading"
              indeterminate
            />
            <h6
              v-else
              class="text-h6 grey--text text-center"
            >
              Nenhuma empresa encontrada
            </h6>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col cols="12">
        <v-autocomplete
          v-model="filter.company_id"
          outlined
          label="Empresa"
          :items="company.items.data"
          :item-value="'id'"
          :item-text="'company_name'"
          rounded
          dense
          hide-details
          @focus.once="getCompanies"
        >
          <template v-slot:no-data>
            <v-progress-linear
              v-if="company.loading"
              indeterminate
            />
            <h6
              v-else
              class="text-h6 grey--text text-center"
            >
              Nenhuma empresa encontrada
            </h6>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col cols="12">
        <v-autocomplete
          v-model="filter.origin_create"
          outlined
          label="Origem do Orçamento"
          :items="origin_create"
          :item-value="'name'"
          :item-text="'name'"
          rounded
          dense
          hide-details
        />
      </v-col>
      <v-col cols="12">
        <v-text-field
          v-model="filter.client_id"
          outlined
          label="Código do Cliente"
          rounded
          dense
          hide-details
        />
      </v-col>
      <v-col cols="12">
        <v-autocomplete
          v-model="filter.client_contact_id"
          :items="contact.items"
          item-text="name"
          item-value="id"
          label="Contato"
          rounded
          hide-details
          outlined
          dense
          @focus.once="getContacts"
        >
          <template v-slot:no-data>
            <v-progress-linear
              v-if="contact.loading"
              indeterminate
            />
            <h6
              v-else
              class="text-h6 grey--text text-center"
            >
              Nenhum contato encontrado
            </h6>
          </template>
        </v-autocomplete>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-icon
          color="primary"
          small
        >
          fas fa-calendar-alt
        </v-icon>
        <span class="text-body-2"> Data de cadastro </span>
      </v-col>
      <v-col cols="6">
        <v-text-field
          v-model="filter.date_from"
          v-mask="['##/##/####']"
          label="De"
          dense
          outlined
          rounded
        />
      </v-col>
      <v-col cols="6">
        <v-text-field
          v-model="filter.date_to"
          v-mask="['##/##/####']"
          label="Até"
          dense
          outlined
          rounded
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import FilterHeader from '@/components/FilterHeader'

export default {
  components: {
    FilterHeader,
  },
  data: () => ({
    filter: {},
    company: {
      items: [],
      loading: true,
    },
    contact: {
      items: [],
      loading: true,
    },
    cancel_reasons: {
      items: [],
      loading: true,
    },
    seller: {
      items: [],
      loading: true,
    },
    status: [
      { name: 'Aguardando', id: 90 },
      { name: 'Enviado', id: 1 },
      { name: 'Aprovado', id: 2 },
      { name: 'Reprovado', id: 3 },
    ],
    origin_create: [{ name: 'site' }, { name: 'adm' }],
  }),
  methods: {
    getCompanies() {
      this.$api
        .get('companies')
        .then((res) => {
          this.company.items = res.data.data
        })
        .catch((err) => {
          console.log(err)
        })
        .finally(() => {
          this.company.loading = false
        })
    },
    getSellers() {
      this.$api
        .get('people/type/sellers')
        .then((res) => {
          this.seller.items = res.data.data
        })
        .catch((err) => {
          console.log(err)
        })
        .finally(() => {
          this.seller.loading = false
        })
    },
    getContacts() {
      this.$api
        .get('client_contacts/all')
        .then((res) => {
          this.contact.items = res.data.data
        })
        .catch((err) => {
          console.log(err)
        })
        .finally(() => {
          this.contact.loading = false
        })
    },
    getCancelReasons() {
      this.$api
        .get(`cancel_reasons`)
        .then((res) => {
          console.log(res.data)
          this.cancel_reasons.items = res.data.data
        })
        .catch((err) => {
          console.log(err)
        }).finally(() => {
          this.cancel_reasons.loading = false
        })
    },
    filterAction() {
      this.$emit('filter', this.filter)
    },
    clearAction() {
      this.$emit('clear')
      this.filter = {}
    },
  },
}
</script>
